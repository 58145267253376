import { useContext, useState } from "react";
import { GlobalContext } from "../../App";
import "./ValuesDealer.scss";


export default function ValuesDealer () {

    const { list, isDealing, setIsDealing } = useContext(GlobalContext);
    const [dealingList, setDealingList] = useState();
    const [counterShowing, setCounterShowing] = useState(0)
    const [isShowingValue, setIsShowingValue] = useState(false)

    const startDealing = () => {
        setIsDealing(true)
        const newDealingList = [];

        for (const item of list) {
            for (let i = 0; i < item.counter; i++) {
                newDealingList.push(item.name);
            }
        }
        setDealingList(shuffle(newDealingList))


    }

    const nextPlayer = () => {
        setCounterShowing(counterShowing + 1)
        setIsShowingValue(false)
        if (counterShowing === dealingList.length - 1) {
            setIsDealing(false)
            setDealingList(null)
            setCounterShowing(0)
        }

    }

    return (<div className={"c-values-dealer t-8"}>
        {!isDealing &&
        <button className={"c-values-dealer__btn b-btn b-btn--min"} onClick={startDealing}>Start Dealing</button>}
        {/*{isDealing &&}*/}

        {isDealing && dealingList && <>
            <div className={"c-values-dealer__content"}>
                {dealingList.map((dealingItem, index) => <div
                        className={"c-values-dealer__value-content" + (counterShowing === index && isShowingValue ? " c-values-dealer__value-content--active" : "")}>
                        <h2
                            key={index}
                            className={"c-values-dealer__value b-text b-text--xl" + (counterShowing === index && isShowingValue ? " c-values-dealer__value--active" : "")}>{dealingItem}</h2>
                    </div>
                )}
                <div className={"c-values-dealer__value-content c-values-dealer__value-content--question"}>
                <span
                    className={"c-values-dealer__value b-text b-text--5xl" + (!isShowingValue ? " c-values-dealer__value--active" : "")}>?</span>
                </div>
            </div>
            {isShowingValue && <button className={"c-values-dealer__btn b-btn b-btn--min"}
                                       onClick={nextPlayer}>{counterShowing === dealingList.length - 1 ? "New game" : "Next player"}</button>}
            {!isShowingValue && <button onClick={() => setIsShowingValue(true)} className={"c-values-dealer__btn" +
            " b-btn" +
            " b-btn--min"}>Show value</button>}
        </>
        }
    </div>)
}

function shuffle (o)
{
    for (var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x) ;
    return o;
}
