import './styles/styles.scss';
import CreationForm from "./components/CreationForm/CreationForm";
import { createContext, useState } from "react";
import ValuesDealer from "./components/ValuesDealer/ValuesDealer";

const defaultData = [
    {
        name: "Persona",
        icon: "",
        counter: 5,
    }, {
        name: "Turtle",
        icon: "",
        counter: 1
    }
]
export const GlobalContext = new createContext(defaultData);

function App () {

    const [list, setList] = useState(defaultData);

    const [isDealing, setIsDealing] = useState(false);

    return (
        <div className="container d-flex align-items-center flex-column">
                <GlobalContext.Provider value={{ list, setList, isDealing, setIsDealing }}>
                    <header className={"my-4 d-flex justify-content-center"}>
                        <a className={"b-text b-text--logo"} href="">Random Hidden</a>
                    </header>
                    <main className={"mb-12 pb-12 u-width-100"}>
                        {!isDealing && <CreationForm/>}
                        <ValuesDealer/>
                    </main>
                </GlobalContext.Provider>
        </div>
    );
}

export default App;
