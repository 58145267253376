import { useContext, useState } from "react";
import { GlobalContext } from "../../App";

export default function CreationForm () {

    const { list, setList } = useContext(GlobalContext);
    const [newValue, setNewValue] = useState("");

    const changeValue = (item, index, newValue, prop) => {
        if (newValue !== 0) {

            const listCopy = [...list];

            listCopy[index] = { ...item, [prop]: newValue }

            setList(listCopy)
        }
    }

    const removeItem = (index) => {
        const listCopy = [...list];

        listCopy.splice(index, 1)
        setList(listCopy)
    }


    const addNewItem = (e) => {
        e.preventDefault()
        setList([...list, { name: newValue, icon: "", counter: 1 }])
        setNewValue("")
    }

    return (
        <div>
            <ul>
                {list.map((item, index) => <li className={"mb-6 row align-items-center"}
                                               key={index}>
                    <div className={"col-12 col-lg-6 d-flex"}>
                        <input placeholder={"Add new value"} className={"b-input"} value={item.name}
                               onChange={(e) => changeValue(item, index, e.target.value, "name")}
                               type="text"/>

                    </div>
                    <div className={"col-12 col-lg-6 d-flex flex-column flex-lg-row justify-content-end"}>

                        <div className={"d-flex justify-content-between justify-content-lg-end align-items-center"}>
                            <button className={"mx-0 mx-lg-6 b-btn"}
                                    onClick={() => {changeValue(item, index, item.counter - 1, "counter")}}>{"<"}</button>
                            <div
                                className={"u-width-60 d-flex align-items-center justify-content-center"}>{item.counter}</div>
                            <button className={"mx-0 mx-lg-6 b-btn"}
                                    onClick={() => {changeValue(item, index, item.counter + 1, "counter")}}>{">"}</button>
                        </div>
                        <button className={"ms-0 ms-lg-6 b-btn"} onClick={() => removeItem(index)}>X</button>


                    </div>
                </li>)}
            </ul>

            <form onSubmit={addNewItem} className={"mt-10 d-flex flex-column flex-lg-row"}>

                <input placeholder={"Add new value"} className={"b-input"} value={newValue}
                       onChange={(e) => setNewValue(e.target.value)}
                       type="text"/>
                <button className={"mt-6 mt-lg-0 ms-0 ms-lg-6 b-btn b-btn--min"}>Add new</button>
            </form>
        </div>
    )
}
